.scrolled-text-container {
  width: 2390px;
  font-family: $primary-font;
  font-size: 100px;
  color: $transparent-green;
  font-weight: 700;
  padding: 200px 0;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @media #{$laptop} {
    font-size: 65px;
    padding: 90px 0;
    margin-left: 5%;
    line-height: 40px;
  }

  @media #{$mobile} {
    font-size: 43px;
    padding: 90px 0;
    margin-left: 25%;
    line-height: 30px;
  }

  .highlight {
    background: linear-gradient(90deg, $scrolled-text-light-blue, $scrolled-text-light-green);
    background-clip: text;
  }

  .line-1-en,
  .line-1 {
    margin-left: -14%;
  }

  .line-2-en,
  .line-2 {
    margin-left: -12%;
  }

  .line-3-en,
  .line-3 {
    margin-left: -8%;

    @media #{$tablet} {
      margin-left: -12%;
    }
  }

  .line-1-en {
    @media #{$mobile} {
      margin-left: -11%;
      font-size: 40px;
    }
  }

  .line-2-en {
    @media #{$mobile} {
      margin-left: -13%;
      font-size: 40px;
    }
  }

  .line-3-en {
    @media #{$mobile} {
      margin-left: -18%;
      font-size: 40px;
    }
  }
}
