$background-color: #ffffff;
$white: #ffffff;
$green: #6eccb1;
$blue: #000c55;
$light-blue: #dff6ff;
$transparent-green: #4fafb626;
$secondary-blue: #9cbffb;
$secondary-green: #59c5a9;
$main-bg: #ffffff75;
$home-card-bg: #ffffff33;
$nav-bar-border: #f2f2f2;
$nav-bar-border-ads: #9cbffb22;
$nav-bar-bg: #ffffffb3;
$nav-bar-btn: #ffffff97;
$scrolled-text-light-blue: #9cbffb;
$scrolled-text-light-green: #8dcaba;
$btn-shadow: #00000029;
$primary-btn-hover: #4fafb6;
$gradient-blue: #6991f8;
$gradient-light-blue: #9cbffb;

$primary-font: 'Ubuntu';
$secondary-font: 'Fira Sans';

$small-mobile: 'only screen and (max-width: 300px)';
$mobile: 'only screen and (max-width: 600px)';
$tablet: 'only screen and (max-width: 768px)';
$laptop: 'only screen and (max-width: 899px)';
$monitor: 'only screen and (max-width: 1200px)';
$big-monitor: 'only screen and (min-width: 1500px)';

$zindex: (
  ebook-background: -1,
);
