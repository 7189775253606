.ebook-ad,
.ads {
  main {
    background-image: none;
  }
}

.ebook-ad {
  body {
    background-image: none;
  }
}

* {
  margin: 0;
  padding: 0;
}

.section {
  max-width: 1200px;
  margin-block: 0;
  margin-inline: auto;
  width: 80%;
}

//#region typography

h1 {
  font-size: 70px;
  font-family: $primary-font;
  font-weight: 700;
  line-height: 1;
  color: $blue;

  @media #{$mobile} {
    font-size: 50px;
    text-align: center;
  }

  @media #{$small-mobile} {
    font-size: 40px;
  }
}

h2,
h3 {
  font-family: $primary-font;
  font-weight: 700;
  margin-top: 0;
  line-height: 1.2;
  color: $blue;
}

h2 {
  font-size: 48px;

  @media #{$mobile} {
    font-size: 36px;
    text-align: center;
  }
}

h3 {
  font-size: 23px;
  color: $light-blue;

  @media #{$mobile} {
    font-size: 18px;
  }
}

.heading-primary {
  color: $blue;

  .highlight {
    color: $green;
  }
}

.text {
  color: $blue;

  .highlight {
    font-weight: 700;
    color: $green;
  }
}

.text-secondary {
  color: $light-blue;
}

.section-container {
  display: flex;
  align-items: center;
}

.section-text,
.link {
  color: $blue;
  font-size: 18px;
  font-family: $secondary-font;
  line-height: 1.6;

  @media #{$mobile} {
    font-size: 16px;
  }
}

.link {
  color: $green;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.faq-payment-methods {
  display: grid;
  grid-template-columns: 1fr 1fr;

  div:last-child {
    ul {
      margin-bottom: 2.5rem;
    }

    p {
      margin: -1.25rem 0 2.5rem -0.75rem;
    }
  }
}

//#endregion

//#region buttons

.plexo-button {
  text-decoration: none;
  border: 1px solid $green;
  font-family: $secondary-font;
  font-size: 18px;
  font-weight: 500;
  padding: 12px 25px;
  display: inline-block;
  border-radius: 25px;
  width: fit-content;

  &:hover {
    box-shadow: 0 4px 8px 1px $btn-shadow;
  }
}

.primary-button {
  color: $white;
  background-color: $green;

  &:hover {
    background-color: $primary-btn-hover;
    border: 1px solid $primary-btn-hover;
  }
}

.secondary-button {
  color: $blue;
  background-color: $white;

  &:hover {
    background-color: $secondary-green;
  }
}

.button-container {
  @media #{$laptop} {
    display: flex;
    justify-content: center;
  }
}

.arrow-icon-down {
  .outline {
    transition: all 1s;
  }

  &:hover {
    .outline {
      transform: scale(1.2) translate(-6%, -6%);
    }
  }
}

#section-anchor-custom,
#section-anchor {
  display: block;
  height: 80px;
  margin-top: -80px;
  margin-bottom: -80px;
  visibility: hidden;

  @media #{$mobile} {
    margin-bottom: unset;
  }
}

#section-anchor-custom {
  margin-top: -20px;
  margin-bottom: -20px;
}

.ads {
  body {
    background-image: none;
  }
}

.ads-background {
  background-color: $blue;
  background-image: url(../assets/img/bg-ads.png);
  background-repeat: no-repeat;
  background-size: 140%;
  background-position: -15rem;
  clip-path: polygon(0 0, 100% 0, 100% 90%, 0 100%);
  height: 750px;
  margin-bottom: -7rem;

  @media #{$laptop} {
    height: 1300px;
    clip-path: polygon(0 0, 100% 0, 100% 97%, 0 100%);
  }

  @media #{$mobile} {
    height: 1400px;
  }

  @media #{$small-mobile} {
    height: 1650px;
  }
}

.grecaptcha-badge {
  z-index: 1000;
  bottom: 110px !important;
}

.container-text-button {
  display: inline-block;
  vertical-align: middle;
  color: $blue;
  width: 80%;
  font-size: 1.375rem;
  line-height: 1.8;
  font-weight: 400;
  font-family: Ubuntu;

  button {
    background-color: transparent;
    color: $green;
    font-size: 1.375rem;
    font-family: Ubuntu;
    border: unset;
    cursor: pointer;
    text-decoration: underline;
  }
}

//#endregion
