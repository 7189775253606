#banner {
  .text-container {
    font-size: 23px;
    color: $blue;
    font-family: $secondary-font;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.75rem;
    justify-content: center;
    text-align: left;

    @media #{$mobile} {
      align-items: center;
      margin-top: 2.25rem;
      gap: 1.5rem;
      text-align: center;
    }

    strong {
      font-weight: 600;
    }

    h1 {
      margin-top: 5.5rem;

      @media #{$mobile} {
        margin-top: 0;
      }

      .highlight {
        color: $green;
      }
    }

    .section-text {
      font-size: 23px;
      max-width: 85%;

      @media #{$tablet} {
        max-width: 100%;
      }
    }

    a {
      .outline {
        transition: all 1s;
      }
    }

    &:hover {
      .outline {
        transform: scale(1.2) translate(-6%, -6%);
      }
    }
  }

  .img-container {
    height: 680px;
    position: relative;

    @media #{$laptop} {
      height: auto;
    }

    .img {
      height: 145%;
      position: absolute;
      left: -20%;
      top: -2%;

      @media #{$laptop} {
        display: none;
      }
    }

    .mobile-img {
      display: none;

      @media #{$laptop} {
        display: block;
        width: 100%;
      }
    }

    .lock-icon {
      position: absolute;
      width: 85px;
      left: -15%;
      bottom: 10%;

      @media #{$laptop} {
        display: none;
      }
    }
  }
}
