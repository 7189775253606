#benefits {
  margin-top: 40px;

  @media #{$laptop} {
    margin-top: 120px;
  }

  .img-container {
    .desktop-image {
      width: 110%;

      @media #{$tablet} {
        margin-left: -50px;
      }

      @media #{$mobile} {
        display: none;
      }
    }

    .mobile-image {
      display: none;
      width: 200%;
      margin-top: -70px;
      margin-bottom: -150px;

      @media #{$mobile} {
        display: block;
      }
    }

    @media #{$mobile} {
      display: flex;
      justify-content: center;
    }
  }

  .text-container {
    h2 {
      margin-bottom: 36px;

      .highlight {
        color: $green;
      }
    }

    .plexo-button {
      margin-top: 46px;
    }
  }
}
