#ads-form {
  font-family: $secondary-font;
  margin-bottom: 2rem;

  p {
    &.MuiFormHelperText-root {
      font-size: 0.75rem;
    }
  }

  @media #{$monitor} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
  }

  .overlay {
    position: absolute;
    background: transparent
      radial-gradient(
        closest-side at 66% 59%,
        $secondary-green 0%,
        $gradient-blue 66%,
        $gradient-light-blue 100%
      )
      0% 0% no-repeat padding-box;
    opacity: 0.4;
    filter: blur(50px);
    position: absolute;
    inset: 0;
    background-position-x: 90px;
    height: 700px;
    width: 700px;
  }

  .form-header,
  .form-container {
    background-color: $white;
    width: 300px;
    position: relative;
    border-radius: 0 0 13px 13px;
    padding: 24px;

    @media #{$monitor} {
      width: 81%;
    }
  }

  .form-header {
    border-radius: 13px 13px 0 0;
    background-color: $white;
    background-image: linear-gradient(20deg, $secondary-green, $gradient-light-blue 97%);
    color: $white;
    text-align: center;
    font-weight: 500;
    font-size: 1.375rem;
  }
}
