.solutions {
  display: grid;
  gap: 1.25rem;
  padding: 1.25rem;
  grid-template-columns: 1fr 1fr 1fr;
  background-color: $light-blue;

  &.MuiList-root {
    padding: 1.25rem;
  }

  @media #{$laptop} {
    grid-template-columns: 1fr;
    gap: 10px;
    padding: 8px;
  }
}

.MuiMenu-paper .solution {
  width: 100%;
  background-color: $white;
  box-sizing: border-box;
  white-space: normal;
  align-items: flex-start;
  pointer-events: none;
  border-radius: 0.75rem;

  &:focus-visible,
  &:focus,
  &:hover {
    outline: 2px solid $green;
    outline-offset: -1px;
    background-color: $white;
  }

  @media #{$laptop} {
    align-items: center;
  }
}

.solution {
  background-color: $white;
  border-radius: 12px;
  color: $blue;
  display: flex;
  flex-direction: column;
  padding: 20px;

  @media #{$laptop} {
    padding: 8px 16px;
    flex-direction: row;
  }

  .solution-icon {
    width: 50px;
    margin-right: 8px;
  }

  h4 {
    font-size: 16px;
    font-family: $primary-font;
    margin: 0;
  }

  p {
    font-size: 14px;
    font-family: $secondary-font;
    line-height: 1.4;
    flex-grow: 1;
    height: 75px;

    @media #{$laptop} {
      display: none;
    }
  }
}

.solution-button {
  display: flex;
  justify-content: left;
  align-items: center;

  @media #{$laptop} {
    justify-content: center;
  }

  a {
    text-decoration: none;
    font-size: 14px;
    font-family: $secondary-font;
    color: $green;
    display: flex;
    align-items: center;
    justify-content: left;
    pointer-events: all;
  }

  img {
    width: 25px;
    margin-left: 8px;
  }

  .solution-button-text {
    @media #{$laptop} {
      display: none;
    }
  }
}

//TODO: remove when product pages go into production
.solution-button-hidden {
  display: none;
}

.solution-title-wrap {
  display: flex;
  align-items: center;
  flex-grow: 1;
  margin-bottom: 10px;

  @media #{$laptop} {
    margin-bottom: 0;
  }
}
