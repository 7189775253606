#ads-footer {
  background-color: $blue;
  color: $white;
  height: 100%;
  display: flex;
  align-items: center;

  .footer-ads-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;

    @media #{$laptop} {
      flex-direction: column;
      gap: 30px;
    }
  }

  .copyright-container {
    display: flex;
    align-items: center;
    font-family: $secondary-font;
    font-size: 1em;
    height: 100%;
  }

  .cedu-container {
    display: flex;
    align-items: center;
    font-family: $primary-font;
    font-size: 0.8125rem;
    height: 100%;

    img {
      width: 90px;
      margin-left: 12px;
    }
  }
}
