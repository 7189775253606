#ebook {
  .nav-bar-ebook {
    background-color: $white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media #{$laptop} {
      padding: 0;
    }

    .nav-bar-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 0 8.5rem;

      @media #{$laptop} {
        justify-content: center;
        background-color: $blue;
        padding: 0;
        height: 80px;
      }
    }

    img {
      width: 10rem;
    }
  }

  .small-title {
    color: $green;
    font-family: $primary-font;
  }

  h1,
  li {
    color: $white;
  }

  li,
  p {
    font-size: 1.125rem;
  }

  h1 {
    font-size: 3.25rem;
    margin-bottom: 1.5rem;

    .highlight {
      color: $green;
    }

    @media #{$laptop} {
      font-size: 2rem;
      max-width: 60%;
    }

    @media #{$mobile} {
      max-width: 87%;
      margin-bottom: 0;
    }
  }

  p {
    font-weight: 500;
    max-width: 85%;

    @media #{$laptop} {
      max-width: 100%;
    }

    &.text {
      @media #{$laptop} {
        max-width: 79%;
        padding: 10% 0;
      }
    }
  }

  li {
    &:before {
      background-image: url('../assets/svg/arrow-list-white.svg');

      @media #{$laptop} {
        background-image: url('../assets/svg/arrow-list.svg');
      }
    }

    @media #{$laptop} {
      color: $blue;
    }
  }

  .copyright-container {
    color: $white;
    font-size: 0.75rem;
    margin-top: 2.5rem;

    @media #{$laptop} {
      background-color: $blue;
      width: 100%;
      height: 3.75rem;
      font-size: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 0;
    }
  }

  .ebook-ad-container {
    height: 100%;
  }

  .text-column {
    background-size: 140%;
    background-color: $blue;
    background-image: url(../assets/img/bg-ads.png);
    background-repeat: no-repeat;
    background-position: 50%;
    padding: 2.5rem 14rem 0rem 8.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.375rem;
    min-height: calc(100vh - 80px);

    @media #{$laptop} {
      padding: 1.5rem 0;
      text-align: center;
      align-items: center;
      gap: 0.5rem;
      min-height: 26rem;
      height: unset;
    }

    @media #{$mobile} {
      background-size: 500%;
      min-height: 28rem;
    }

    @media #{$small-mobile} {
      min-height: 30rem;
    }

    .text-items {
      display: flex;
      flex-direction: column;
      gap: 1.375rem;

      p {
        color: $white;
      }
    }
  }

  .form-column {
    background: rgb(156, 191, 251);
    background: linear-gradient(20deg, rgba(156, 191, 251, 1) 25%, rgba(89, 197, 169, 1) 97%);
    min-height: 24rem;
    position: relative;
  }

  #ads-form {
    position: absolute;
    top: 10rem;
    right: 30%;

    @media #{$big-monitor} {
      right: 45%;
    }

    @media #{$laptop} {
      position: unset;
      margin-top: -5rem;
    }

    .form-container {
      border-radius: 13px;
      width: 18.2rem;
      padding: 2.4rem 1.45rem;
      display: flex;
      flex-direction: column;
      gap: 8px;

      @media #{$laptop} {
        width: 45%;
      }
      @media #{$tablet} {
        width: 71%;
      }
    }
  }

  .list-item {
    width: 50%;

    @media #{$laptop} {
      grid-template-columns: 1fr;
      width: 100%;
    }
  }

  .plexo-button {
    @media #{$laptop} {
      width: fit-content;
      padding: 0.75rem 1.4rem;
      display: flex;
      justify-content: center;
      margin: 10% 0;
    }

    @media #{$mobile} {
      width: 100%;
      padding: 0.75rem 0;
    }
  }

  .rocket-icon {
    position: absolute;
    width: 30%;
    left: -68%;
    top: 17rem;

    @media #{$big-monitor} {
      top: 14rem;
    }

    @media #{$monitor} {
      transform: scaleX(-1);
      width: 15%;
      left: 60%;
      top: 35rem;
    }

    @media #{$mobile} {
      width: 19%;
      left: 75%;
      top: 30rem;
    }
  }

  #text-items-mobile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 11%;
    position: relative;

    p {
      color: $blue;
    }

    @media #{$mobile} {
      padding: 0 9%;
    }
  }

  .overlay {
    background: transparent
      radial-gradient(
        closest-side at 66% 59%,
        $secondary-green 50%,
        $gradient-blue 66%,
        $gradient-light-blue 100%
      )
      0% 0% no-repeat padding-box;
    opacity: 0.65;
    filter: blur(50px);
    position: absolute;
    inset: 0;
    height: 320px;
    width: 320px;
    top: 320px;
    left: 120px;
    z-index: map-get($zindex, ebook-background);
  }

  .grecaptcha-badge {
    bottom: 0 !important;
  }
}
