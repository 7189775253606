#marquee {
  width: 3000px;
  background-color: $green;
  background-size: cover;
  transform: rotate(-3deg);
  font-size: 18px;
  font-family: $secondary-font;
  color: $blue;
  margin-top: -50px;
  margin-left: -10px;
  padding: 20px 0;
  display: flex;

  img {
    margin-right: 10px;
  }

  .texts-container {
    width: 100%;
    animation: marquee 15s linear infinite;
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    @media #{$mobile} {
      width: 40%;
    }
  }

  .text {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    user-select: none;
  }

  @media #{$laptop} {
    margin-top: -75px;
  }

  @media #{$mobile} {
    font-size: 13px;
    padding: 12px 0;
  }
}

@keyframes marquee {
  0% {
    transform: translate(-100%, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
