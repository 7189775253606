.nav-bar-ebook,
.nav-bar-ads,
.nav-bar-from-ads,
.nav-bar {
  height: 50px;
  top: 0;
  padding: 15px 0px;
  z-index: 9999;
  position: fixed;
  width: 100vw;
  background-color: transparent;
  backdrop-filter: none;
  border-bottom: 1px solid $nav-bar-border;

  &.nav-bar-404 {
    @media #{$monitor} {
      background-color: $white;
      margin: 0;
    }
  }

  &.scrolling {
    background-color: $nav-bar-bg;
    backdrop-filter: blur(5px);
    border-bottom: none;

    @media #{$monitor} {
      background-color: $white;
      backdrop-filter: none;
      margin: 0;
    }
  }

  .plexo-logo {
    width: 115px;
  }

  .nav-bar-wrap {
    display: flex;
    align-items: center;
    justify-content: center;

    @media #{$monitor} {
      justify-content: space-between;
    }
  }
}

.nav-bar-items-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  height: 80%;
  flex-grow: 1;
  margin: 0 0 0 44px;

  @media #{$monitor} {
    display: none;
    position: fixed;
    top: 80px;
    left: 0;
    background-color: $light-blue;
    width: 100%;
    flex-direction: column;
    margin: 0;
    height: calc(100vh - 80px);
    overflow: auto;
    text-align: center;

    &.show {
      display: block;
    }
  }

  .nav-bar-menu-items {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;

    @media #{$monitor} {
      flex-direction: column;
      padding-top: 8px;
      width: 100%;
      justify-content: center;
      align-items: center;
      flex-grow: 0;
      text-align: center;
    }
  }

  .menu-item,
  .menu-item-active {
    font-family: $secondary-font;
    color: $blue;
    font-size: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;

    svg {
      cursor: pointer;
      margin-left: 6px;
      path {
        stroke: $blue;
      }
    }

    @media #{$monitor} {
      justify-content: center;
      align-items: center;
      margin: 26px 0px;
      font-size: 18px;
    }
  }

  .menu-item-active {
    color: $green;
    font-weight: 500;
    svg {
      path {
        stroke: $green;
      }
    }
  }

  a {
    font-family: $secondary-font;
    text-decoration: none;
    color: $blue;
  }

  .nav-bar-button {
    font-size: 18px;
    font-weight: 600;
    border: solid 1.2px $green;
    background-color: $nav-bar-btn;
    padding: 13px 20px;
    border-radius: 24px;
    display: inline-block;

    @media #{$mobile} {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 160px;
      margin: 16px auto;
    }
  }
}

.burguer-btn {
  display: none;

  @media #{$monitor} {
    display: block;
    border: 1.5px solid $secondary-blue;
    border-radius: 12px;
  }
}

.dropdown {
  margin: 0 auto;
  background-color: $light-blue;
  border-radius: 20px;
  width: 80%;
}

.mobile-menu {
  display: none;
  @media #{$monitor} {
    display: block;
  }
}

.language-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  margin-left: 1.25rem;

  .language-separator {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
}

.language-button {
  background-color: transparent;
  border: none;
  font-size: 16px;
  color: $blue;
  font-family: $secondary-font;
  font-weight: 400;
  padding: 0;
  cursor: pointer;

  &:first-child {
    margin-left: auto;
  }
  &:last-child {
    margin-right: auto;
  }

  &:hover,
  &:focus {
    transition: all 0.5s;
    color: $primary-btn-hover;
  }

  &.active,
  &:not(.active) {
    border-bottom-width: 2.5px;
    border-bottom-style: solid;
  }

  &.active {
    border-bottom-color: $green;
  }

  &:not(.active) {
    border-bottom-color: transparent;
  }
}

.nav-bar-from-ads,
.nav-bar-ads {
  border-bottom: 1px solid $nav-bar-border-ads;

  .plexo-logo {
    @media #{$monitor} {
      width: 170px;
    }
  }

  .nav-bar-menu-items {
    visibility: hidden;
  }

  .burguer-btn {
    @media #{$monitor} {
      display: none;
    }
  }

  .nav-bar-wrap {
    @media #{$monitor} {
      justify-content: center;
    }
  }

  .language-wrap {
    display: none;
  }

  &.scrolling {
    .nav-bar-button {
      color: $blue;
    }
  }
}

.nav-bar-ads {
  .nav-bar-button {
    background-color: transparent;
    color: $white;
  }
}

.nav-bar-ebook {
  background-color: $white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media #{$laptop} {
    padding: 0;
  }

  .nav-bar-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 8.5rem;

    @media #{$laptop} {
      justify-content: center;
      background-color: $blue;
      padding: 0;
      height: 80px;
    }
  }

  img {
    width: 10rem;
  }
}
