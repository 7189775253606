#certification {
  margin-top: 80px;

  @media #{$tablet} {
    margin-top: 100px;
  }

  .img-container {
    img {
      width: 120%;
      margin-left: -120px;

      @media #{$tablet} {
        width: 140%;
      }

      @media #{$mobile} {
        width: 180%;
        margin: -8% 0 -20% -38%;
      }
    }

    .button-container {
      display: none;

      @media #{$mobile} {
        display: flex;
      }
    }

    @media #{$tablet} {
      order: 2;
    }
  }

  .text-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  h2 {
    .highlight {
      color: $green;
    }
  }

  .section-text {
    max-width: 94%;
    margin-bottom: 25px;

    strong {
      color: $green;
      font-weight: 700;
    }

    @media #{$mobile} {
      max-width: 100%;
      text-align: center;
    }
  }
}
