#payment-methods-anchor {
  display: block;
  height: 80px;
  margin-top: -80px;
  visibility: hidden;
}

#payment-methods {
  margin-top: 180px;

  .img-container {
    background-image: url(../../../assets/img/radial-gradient-1.png);
    background-size: 110%;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    padding-bottom: 10%;
    padding-top: 10%;

    @media #{$laptop} {
      display: none;
    }

    img {
      max-width: 70px;
      max-height: 70px;
    }

    .button-container {
      display: flex;
      align-items: center;
      justify-content: left;
    }

    .button {
      text-decoration: none;
      font-family: $secondary-font;
      color: $blue;
      display: flex;
      align-items: center;
      background-color: $white;
      padding: 15px 20px;
      border-radius: 30px;

      img {
        margin-left: 8px;
        width: 35px;
        height: 35px;
      }
    }

    .mobile-logos-slider {
      @media #{$laptop} {
        flex-wrap: nowrap;
        display: flex;
      }
    }

    @media #{$laptop} {
      background-size: contain;
      background-position: center;
      order: 2;
    }
  }

  .text-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    @media #{$mobile} {
      gap: 1.25rem;
    }

    h2 {
      @media #{$laptop} {
        text-align: left;
      }
    }

    .highlight {
      color: $green;
    }
  }

  @media #{$laptop} {
    order: 1;
  }
}

.img-container-mobile {
  display: none;

  @media #{$laptop} {
    display: block;
  }

  .slider-container-mobile {
    background-image: url(../../../assets/img/radial-gradient-1.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: center;
    justify-self: center;
    height: 300px;
    margin-top: -20px;
  }

  .mobile-button {
    display: flex;
    justify-content: center;
    margin-top: -90px;
  }
}
