#products {
  background-color: $blue;
  margin-top: 180px;

  .section {
    width: 65%;

    @media #{$monitor} {
      min-width: 80%;
    }

    @media #{$mobile} {
      min-width: 90%;
    }
  }

  h2 {
    max-width: 95%;
    text-align: center;
    color: $light-blue;
    font-size: 70px;
    font-family: $primary-font;
    font-weight: 700;
    margin-top: 150px;
    margin-bottom: 1.5rem;

    .highlight {
      color: $green;
    }

    @media #{$mobile} {
      font-size: 36px;
      margin-top: 60px;
      margin-bottom: 60px;
      text-align: center;
    }
  }

  .cards-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 70px;

    @media #{$laptop} {
      margin-top: 0;
    }
  }

  .card {
    background-color: $home-card-bg;
    width: 80%;
    height: 100%;
    text-align: left;
    border-top-left-radius: 1000px;
    border-top-right-radius: 1000px;
    border-bottom-left-radius: 150px;
    border-bottom-right-radius: 150px;
    padding: 25px;
    display: flex;
    flex-direction: column;

    @media #{$laptop} {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: auto;
      border-radius: 20px;
    }

    img {
      width: 100%;

      @media #{$laptop} {
        margin-right: 0;
        width: 40%;
        height: 40%;
      }
    }

    .text-container {
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      @media #{$mobile} {
        margin-left: 10px;
      }

      h3 {
        margin-bottom: 18px;
        margin-top: 20px;

        @media #{$mobile} {
          font-size: 18px;
          margin-bottom: 10px;
          margin-top: 0;
        }
      }

      .text {
        font-size: 18px;
        flex-grow: 1;
        line-height: 1.4;
        color: $light-blue;
        font-family: $secondary-font;

        .highlight {
          color: $green;
          font-weight: 500;
        }

        @media #{$mobile} {
          font-size: 16px;
          width: 90%;
        }
      }
    }
  }

  .button {
    a {
      text-decoration: none;
      font-family: $secondary-font;
      color: $green;
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: left;

      @media #{$mobile} {
        margin-top: 15px;
        font-size: 16px;
      }
    }

    .img-container {
      display: flex;
      align-items: center;
    }

    img {
      width: 15%;
      margin-left: 10px;

      @media #{$mobile} {
        margin-right: 0;
        width: 18%;
      }
    }
  }
}
