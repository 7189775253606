//#region input

.MuiTextField-root {
  .MuiInput-root {
    &::before,
    &::after,
    &:hover:not(.Mui-disabled)::before {
      border-color: $green;
    }
  }
}

//#endregion
