.list-item {
  color: $blue;
  font-size: 18px;
  font-family: $secondary-font;
  line-height: 25px;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem 2.5rem;

  @media #{$tablet} {
    font-size: 16px;
    grid-template-columns: 1fr;
  }

  li {
    display: flex;
    align-items: flex-start;
    list-style: none;
    line-height: 1.4;
    text-align: left;

    &:before {
      display: block;
      flex-shrink: 0;
      width: 23px;
      height: 23px;
      background-image: url('../../../assets/svg/list-type.svg');
      background-repeat: no-repeat;
      background-position: left center;
      background-size: contain;
      content: '';
      margin-right: 10px;
    }
  }

  li.text-secondary {
    &:before {
      background-image: url('../../../assets/svg/list-type-secondary.svg');
    }
  }

  img {
    margin-right: 10px;
  }
}
