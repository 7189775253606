#clients {
  margin-top: 60px;

  @media #{$mobile} {
    margin-bottom: -60px;
  }

  h2 {
    margin-bottom: 1.5rem;

    .highlight {
      color: $green;
    }

    @media #{$laptop} {
      text-align: left;
    }
  }
}

.tns-slider {
  display: flex;
  align-items: center;
}
